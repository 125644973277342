import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// Adding interceptor so that on every request, authorization header should be available
instance.interceptors.request.use((config) => {
  const updated = {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        authorization: `Bearer ${localStorage.getItem('boostopia-token')}`,
      },
    },
  };
  return updated;
});

instance.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => {
    let message = '';
    if (!err.response || !err.response?.data) message = err.message;
    else if (typeof err.response?.data?.errorMessage === 'object') {
      const e = err?.response?.data?.errorMessage;
      if (e.message) {
        message = e.message;
      } else message = e[Object.keys(e)[0]]?.toString();
    } else message = err.response?.data.errorMessage.toString();
    return Promise.reject({ message, errorMessage: message }); // sending both keys because of inconsistencies
  },
);

// auth roures

// eslint-disable-next-line no-undef
export const login = (data = { email, password }, userType = 'customer') =>
  instance.post(`/auth/login/${userType}`, data);

export const forgotPassword = (email, userType) =>
  instance.post(`/auth/forgot-password/${userType}`, email);

// eslint-disable-next-line no-undef
export const resetPassword = (data = { email, password, confirmPassword }, userType, token) =>
  instance.post(`auth/reset-password/?userType=${userType}&token=${token}`, data);

export const validateToken = (userType, token) =>
  instance.get(`auth/validate-token/?userType=${userType}&token=${token}`);

// eslint-disable-next-line no-undef
export const registerCustomer = (data = { username, email, password, confirmPassword }) =>
  instance.post('/auth/register/customer', data);

// eslint-disable-next-line no-undef
export const registerBooster = (data = { username, email, password, confirmPassword }) =>
  instance.post('/auth/register/booster', data);

// eslint-disable-next-line no-undef
export const googleLogin = (data = { credential, type, userType }) =>
  instance.post(`/auth/google-login/${data.userType}`, data);

export const updateCustomerProfile = (data) => {
  const fd = new FormData();
  Object.keys(data).forEach((k) => {
    if (data[k]) fd.set(k, data[k]);
  });
  return instance.post('/customer/auth', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateCustomerContact = (data) => instance.post('/customer/contact', data);

// customer routes
export const getOrder = (id) => instance.get(`customer/orders/${id}`);

export const createDivisionBoostOrder = (data) =>
  instance.post('customer/orders/division-boost', data);

export const createNetWinsOrder = (data) => instance.post('customer/orders/net-wins', data);

export const createPlacementGamesOrder = (data) =>
  instance.post('customer/orders/placement-games', data);

export const confirmOrder = (id) => instance.get(`customer/orders/${id}/confirm-checkout`);

export const updateAdditionalInfo = (id, data) =>
  instance.post(`customer/orders/${id}/additional-information`, data);

export const getCustomerOrders = () => instance.get('customer/orders');

export const getCustomerOrder = (orderId) => instance.get(`customer/orders/${orderId}`);

export const togglePauseOrder = (orderId) =>
  instance.put(`customer/orders/${orderId}/toggle-pause`);

export const updateOrderAccountInfo = (orderId, data) =>
  instance.post(`customer/orders/${orderId}/account-information`, data);

export const rejectOrderCompletion = (orderId) =>
  instance.put(`customer/orders/${orderId}/reject-completion`);

export const completeOrderByCustomer = (orderId) =>
  instance.put(`customer/orders/${orderId}/complete`);

// booster routes
export const updateBoosterProfileImage = (img) => {
  const fd = new FormData();
  fd.set('profileImage', img);
  return instance.put('/booster/profileImage', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateBoosterPhone = (phoneNumber) =>
  instance.put('/booster/phone-number', { phoneNumber });

export const updateBoosterInfo = (data) => instance.put('/booster/personal-info', data);

export const updateBoosterSocials = (data) => instance.put('/booster/social-links', data);

export const updateBoosterProfileInfo = (data) => instance.put('/booster/profile-info', data);

export const getAllPendingOrders = (gameMode, server) =>
  instance.get(`/booster/pending-orders?gameMode=${gameMode}&server=${server}`);

export const getAllBoosterOrders = (status, gameMode, server) =>
  instance.get(`booster/orders?status=${status}&gameMode=${gameMode}&server=${server}`);

export const claimBoosterOrder = (orderId) => instance.put(`booster/orders/${orderId}/claim`);

export const cancelBoosterOrder = (orderId) => instance.put(`booster/orders/${orderId}/cancel`);

export const completeOrderByBooster = (orderId) =>
  instance.put(`booster/orders/${orderId}/complete`);

// only for admin
// export const autoConfirmOrderByCustomer = (status) =>
// instance.put(`/customer/confirm-order?${status ? 'status=' + status: ''}`);

export const getBoosterOrder = (orderId) => instance.get(`booster/orders/${orderId}`);

export const getBoosterNextPayout = () => instance.get('booster/next-payout');

export const updateNextPayout = (data) => instance.put('booster/next-payout', data);

export const sendPaypalPayout = (data) => instance.put('booster/payout', data);

export const boosterTransaction = () => instance.get('booster/transaction');

export const getBoosters = (region) => instance.get(`booster/boosters?region=${region}`);

//admin routes
export const adminGetCustomers = (email, page) =>
  instance.get(`/admin/customers?${email ? 'email=' + email : ''}${page ? '&page=' + page : ''}`);

export const adminGetBoosters = (email, tier, page) =>
  instance.get(
    `/admin/boosters?${email ? 'email=' + email : ''}${tier ? 'tier=' + tier : ''}${
      page ? '&page=' + page : ''
    }`,
  );

export const adminGetOrders = (status, page) =>
  instance.get(`/admin/orders?${status ? 'status=' + status : ''}${page ? '&page=' + page : ''}`);

export const adminGetOrder = (orderId) => instance.get(`admin/orders/${orderId}`);

export const adminCompleteOrder = (orderId) => instance.put(`admin/orders/${orderId}/complete`);

export const autoConfirmOrderByAdmin = (status) =>
  instance.put(`/admin/confirm-order?${status ? 'status=' + status : ''}`);

export const adminGetBooster = (boosterId) => instance.get(`/admin/boosters/${boosterId}`);

export const adminToggleApproveBooster = (boosterId) =>
  instance.put(`/admin/boosters/${boosterId}/toggle-approve`);

export const getInvoices = (type, currency, page) =>
  instance.get(`/admin/invoice?${page ? '&page=' + page : ''}`, {
    params: {
      type: type,
      currency: currency,
    },
  });

export const getInvoiceById = (invoiceId) => instance.get(`/admin/invoice/${invoiceId}`);

export const getBoosterInvoiceById = (invoiceId) =>
  instance.get(`/admin/booster/invoice/${invoiceId}`);

// other routes
export const sendContactMail = (data) => instance.post('/mailing/contact-mail', data);

export const getSummonerAccount = (
  data = { gameName: '', tagLine: '', server: '', gameMode: '' },
) => instance.post('/summoner-account', data);

export const sendMoney = (payout) => instance.post('/wise/send-money', payout);

export const accountAdd = (payout) => instance.post('/accounts/account', payout);

export const getAllAccounts = (requestFilter) =>
  instance.get('/auth/accounts', { params: requestFilter });

export const buyAccount = (body) => instance.post('accounts/buy-account', body);

export const getAccount = (id) => instance.get(`accounts/account/${id}`);

export const confirmAccount = (id) => instance.get(`accounts/account/${id}/confirm-checkout`);

export const getCustomerAccount = () => instance.get('accounts/customer-account');

export const getAllAccountByAdmin = (page) =>
  instance.get(`accounts/admin-accounts?${page ? '&page=' + page : ''}`);

export const updateAccountDetails = (accountId, updateData) =>
  instance.post(`/accounts/${accountId}/update`, updateData);

export const deleteAccount = (accountId) => instance.post(`accounts/${accountId}/delete`);

export default instance;
