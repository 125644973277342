const AccountShopHeader = () => {
  // used for staging / production
  const domain =
    process.env.REACT_APP_API_ENDPOINT === 'http://localhost:5000'
      ? 'http://localhost:3000'
      : process.env.REACT_APP_API_ENDPOINT;

  // use preloaded images
  const accountShHeaderXsm = domain + '/account-sh-header-xsm.webp';
  const accountShHeaderMd = domain + '/account-sh-header-md.webp';
  const accountShHeaderLg = domain + '/account-sh-header-lg-300.webp';

  return (
    <>
      <div className="header">
        <div className="h-content" style={{ marginTop: '2rem' }}>
          <h1 className="fs-1">ACCOUNT SHOP</h1>
          <h3>Security? Guaranteed.</h3>
        </div>
        <figure>
          <picture>
            <source
              media="(max-width: 480px)"
              srcSet={accountShHeaderXsm}
              data-src={accountShHeaderXsm}
            />
            <source
              media="(max-width: 1400px)"
              srcSet={accountShHeaderMd}
              data-src={accountShHeaderMd}
            />
            <source
              media="(min-width: 1401px)"
              srcSet={accountShHeaderLg}
              data-src={accountShHeaderLg}
            />
            <img
              className="account-shop-header"
              src={accountShHeaderLg}
              data-src-base="../../assets/images/"
              data-src={`{xs:images/${accountShHeaderXsm},md:${accountShHeaderMd},xl:${accountShHeaderLg}}`}
              alt="Account Shop Header"
              style={{
                minHeight: '20rem',
                maxHeight: '25rem',
                objectFit: 'cover',
              }}
              draggable="false"
            />
          </picture>
        </figure>
      </div>
    </>
  );
};

export default AccountShopHeader;
