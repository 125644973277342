const BoostingHeader = () => {
  // used for staging / production
  const domain =
    process.env.REACT_APP_API_ENDPOINT === 'http://localhost:5000'
      ? 'http://localhost:3000'
      : process.env.REACT_APP_API_ENDPOINT;

  // use preloaded images
  const divisionHeaderXsm = domain + '/division-header-xsm.webp';
  const divisionHeaderMd = domain + '/division-header-md.webp';
  const divisionHeaderLg = domain + '/division-header-lg-300height.webp';

  return (
    <div className="header">
      <div className="h-content" style={{ marginTop: '2rem' }}>
        <h1 className="fs-1">LEAGUE BOOSTING</h1>
        <h3>You are almost there!</h3>
      </div>
      <figure>
        <picture>
          <source
            media="(max-width: 480px)"
            srcSet={divisionHeaderXsm}
            data-src={divisionHeaderXsm}
          />
          <source
            media="(max-width: 1400px)"
            srcSet={divisionHeaderMd}
            data-src={divisionHeaderMd}
          />
          <source
            media="(min-width: 1401px)"
            srcSet={divisionHeaderLg}
            data-src={divisionHeaderLg}
          />
          <img
            className="boosting-header"
            src={divisionHeaderLg}
            data-src-base="../../assets/images/"
            data-src={`{xs:${divisionHeaderXsm},md:${divisionHeaderMd},xl:${divisionHeaderLg}}`}
            alt="Boosting Header"
            style={{
              minHeight: '20rem',
              maxHeight: '25rem',
              objectFit: 'cover',
            }}
            draggable="false"
          />
        </picture>
      </figure>
    </div>
  );
};

export default BoostingHeader;
