export default function SvgDiscord(props) {
  return (
    <svg
      className="m-0"
      width="16"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8199 0.259766C13.6213 0.615453 13.4443 0.982785 13.2899 1.35977C11.7727 1.11982 10.2272 1.11982 8.70992 1.35977C8.55551 0.982785 8.37852 0.615453 8.17992 0.259766C6.75071 0.503967 5.36132 0.941303 4.04992 1.55977C1.70486 4.94405 0.641354 9.05272 1.04992 13.1498C2.57815 14.2986 4.29338 15.1748 6.11992 15.7398C6.53583 15.1904 6.90702 14.6085 7.22992 13.9998C6.63399 13.78 6.06121 13.502 5.51992 13.1698C5.66838 13.071 5.80881 12.9606 5.93992 12.8398C7.51838 13.6001 9.24789 13.9949 10.9999 13.9949C12.7519 13.9949 14.4815 13.6001 16.0599 12.8398C16.1999 12.9598 16.3399 13.0698 16.4799 13.1698C15.9357 13.4994 15.3635 13.7805 14.7699 14.0098C15.0794 14.6323 15.4407 15.2277 15.8499 15.7898C17.6742 15.2268 19.3864 14.3504 20.9099 13.1998C21.3283 9.10197 20.2639 4.98995 17.9099 1.60977C16.6132 0.978503 15.2376 0.524436 13.8199 0.259766ZM7.67992 10.8098C7.1795 10.7738 6.71246 10.5455 6.37684 10.1725C6.04122 9.79962 5.86311 9.31119 5.87992 8.80977C5.86058 8.30768 6.0378 7.81785 6.37393 7.44438C6.71005 7.0709 7.17858 6.84324 7.67992 6.80977C8.18126 6.84324 8.64978 7.0709 8.9859 7.44438C9.32203 7.81785 9.49926 8.30768 9.47992 8.80977C9.49926 9.31185 9.32203 9.80168 8.9859 10.1752C8.64978 10.5486 8.18126 10.7763 7.67992 10.8098ZM14.3199 10.8098C13.8195 10.7738 13.3525 10.5455 13.0168 10.1725C12.6812 9.79962 12.5031 9.31119 12.5199 8.80977C12.5006 8.30768 12.6778 7.81785 13.0139 7.44438C13.3501 7.0709 13.8186 6.84324 14.3199 6.80977C14.8222 6.84074 15.2922 7.06767 15.6289 7.44171C15.9655 7.81576 16.1418 8.30702 16.1199 8.80977C16.1418 9.31251 15.9655 9.80377 15.6289 10.1778C15.2922 10.5519 14.8222 10.7788 14.3199 10.8098Z"
        fill="white"
      ></path>
    </svg>
  );
}
