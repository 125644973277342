import { useState } from 'react';

const useAxios = ({ onSuccess, onError, onFulfilled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const makeRequest = async (promise) => {
    if (!(promise instanceof Promise))
      throw new Error('makeRequest expects a promise as an argument');
    try {
      setIsLoading(true);
      const data = await promise;
      setData(data);
      setError(null);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      setError(error.message);
      setData(null);
      if (onError) {
        onError(error.message);
      }
    } finally {
      setIsLoading(false);
      if (onFulfilled) {
        onFulfilled();
      }
    }
  };

  return {
    isLoading,
    error,
    data,
    makeRequest,
  };
};

export default useAxios;
