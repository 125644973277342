import { atom, useAtom } from 'jotai';

const toastsStateAtom = atom([]);

export default function useToasts() {
  const [toasts, setToasts] = useAtom(toastsStateAtom);

  const removeToast = (id) => {
    setToasts((prevState) => prevState.filter((t) => t.id !== id));
  };

  const addToast = (message, variant) => {
    const id = Math.random() + Date.now();
    const newToast = { id, message, variant };
    setToasts((prevState) => [...prevState, newToast]);
    setTimeout(() => removeToast(id), 5000);
    return id;
  };

  return { toasts, addToast, removeToast };
}
