import { atom, useAtom } from 'jotai';

const authAtom = atom({
  user: localStorage.getItem('boostopia-user')
    ? JSON.parse(localStorage.getItem('boostopia-user'))
    : null,
});

export default function useAuth() {
  const [state, setState] = useAtom(authAtom);

  const setUser = (usr) => {
    localStorage.setItem('boostopia-user', JSON.stringify(usr));
    setState({ ...state, user: usr });
  };

  const loginUser = (usr) => {
    localStorage.setItem('boostopia-user', JSON.stringify(usr));
    localStorage.setItem('boostopia-token', usr.accessToken);
    setState({ ...state, user: usr });
  };

  const logoutUser = () => {
    localStorage.removeItem('boostopia-user');
    localStorage.removeItem('boostopia-token');
    sessionStorage.removeItem('isOpened');
    setState({ ...state, user: null });
  };

  return { ...state, setUser, loginUser, logoutUser };
}
