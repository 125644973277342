// import trustpilotStars from '../../assets/icons/trustpilot-stars.svg';
// import trustpilotLogo from '../../assets/icons/tp-logo-black.svg';

const TrustpilotBar = (data) => {
  // const isMobile = window.innerWidth <= 500;

  return (
    <div ref={data.myRef} className="review-trustpilot-bar" id="my-tp-bar">
      <div className="container">
        <span className="review-trustpilot">
          {/* <div className="d-flex justify-content-center">
            <a
              className="d-flex align-items-center gap-2 text-black text-decoration-none"
              target="_blank"
              href="https://www.trustpilot.com/review/boostopia.gg"
              rel="noreferrer"
            >
              <strong className="fs-6" style={{ fontWeight: 600 }}>
                Excellent
              </strong>
              <img
                draggable="false"
                src={trustpilotStars}
                alt="5 Trustpilot stars"
                style={{
                  position: 'relative',
                  height: '22px',
                  width: '110px',
                  left: '0',
                  top: '0',
                }}
              />
              <span
                style={{
                  fontSize: '0.8rem',
                  display: isMobile ? 'none' : 'block',
                }}
              >
                <strong style={{ fontWeight: 600 }}>100+</strong>
                <span> reviews on</span>
              </span>
              <img
                draggable="false"
                src={trustpilotLogo}
                alt="Trustpilot"
                style={{
                  position: 'relative',
                  height: '22px',
                  width: '90px',
                  left: '0',
                  top: '0',
                }}
              />
            </a>
          </div> */}
        </span>
      </div>
    </div>
  );
};

export default TrustpilotBar;
