import { Link } from 'react-router-dom';
import { jinx, ryze } from '../../imageLoader/images';

const Services = () => {
  return (
    <div className="our-services">
      <div className="container container-1170">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Our Services</h2>
            <p>Currently available for League of Legends only.</p>
          </div>

          <div className="col-md-6">
            <div className="services-info">
              <h3>Rank Boosting</h3>
              <p>Choose your desired rank and we will help you achieve it.</p>
              <Link to="/boosting" type="button" className="btn btn-outline-light my-hover my-font">
                Discover
              </Link>
              <img src={jinx} alt="jinx" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="services-info info-transparent">
              <h3>Account Shop</h3>
              <p>
                Superior warranty.
                <br />
                Guaranteed security.
              </p>
              <Link to="/accounts" type="button" className="btn btn-outline-light my-hover my-font">
                Check Out
              </Link>
              <img src={ryze} alt="ryze" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
