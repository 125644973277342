import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Layout from '../../pages/layout/layout';
import useAuth from '../../hooks/useAuth';
import useToasts from '../../hooks/useToasts';

function MainWebLayout() {
  const { user, loginUser } = useAuth();
  const loc = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  React.useEffect(() => {
    const params = new URLSearchParams(loc.search);
    if (!user && params.get('user')) {
      loginUser(JSON.parse(params.get('user')));

      setTimeout(() => {
        const parsedData = JSON.parse(localStorage.getItem('originalUrls'));
        const parts = parsedData?.split('/'); // Split the URL into parts using "/"

        if (parts?.length > 4) {
          const dataAfterFirstSlash = `${parts[3]}/${parts[4]}`;
          navigate(dataAfterFirstSlash);
        } else {
          const dataAfterFirstSlash = parts[3];
          navigate(dataAfterFirstSlash);
        }

        const isBuyBoostButtonClicked = localStorage.getItem('buy-boost-button-clicked');
        const isBuyAccountButtonClicked = localStorage.getItem('buy-account-clicked');

        if (!isBuyBoostButtonClicked && !isBuyAccountButtonClicked) {
          navigate('/admin/dashboard');
        } else {
          // login if user is not login and try to place order
          addToast('Login successfull. Redirecting to Stripe...', 'success');
        }
      }, 200); // goto dashboard after some delay
    }
  }, [loc, user]);
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default MainWebLayout;
