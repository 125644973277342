import { Fragment, useEffect, useRef, useState } from 'react';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';

const TopBar = ({ accounts, setSortedAccounts }) => {
  const [selectedSort, setSelectedSort] = useState('Newest');
  const [selectedCollection, setSelectedCollection] = useState([]);

  useEffect(() => {
    // every time the dependencies change, filter and sort the accounts
    const newFilteredAccounts = filterAccountsByCollection(accounts);
    const newSortedAccounts = sortAccountsBySelection(newFilteredAccounts);
    setSortedAccounts(newSortedAccounts);
  }, [accounts, selectedCollection, selectedSort]);

  const sortAccountsBySelection = (accList) => {
    if (!accList || accList.length === 0) {
      return [];
    }
    let sortedAccounts;
    switch (selectedSort) {
      case 'Newest':
        sortedAccounts = [...accList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case 'LowestPrice':
        sortedAccounts = [...accList].sort((a, b) => a.price - b.price);
        break;
      case 'MostChamps':
        sortedAccounts = [...accList].sort(
          (a, b) => b.accCheckerData.ownedChampionsCount - a.accCheckerData.ownedChampionsCount,
        );
        break;
      case 'MostSkins':
        sortedAccounts = [...accList].sort(
          (a, b) => b.accCheckerData.ownedSkinsCount - a.accCheckerData.ownedSkinsCount,
        );
        break;
      case 'FewestSkins':
        sortedAccounts = [...accList].sort(
          (a, b) => a.accCheckerData.ownedSkinsCount - b.accCheckerData.ownedSkinsCount,
        );
        break;
      default:
        sortedAccounts = accList;
    }
    return sortedAccounts;
  };

  const filterAccountsByCollection = (accounts) => {
    // if no collection is selected, show all accounts
    if (selectedCollection.length === 0) {
      return accounts;
    }
    // filter accounts by selected collection
    const filteredAccounts = accounts.filter((account) => {
      const ownedChamps = account.accCheckerData.ownedChampions[0].split(',');
      const ownedSkins = account.accCheckerData.ownedSkins[0].split(',');
      const ownedChromas = account.accCheckerData.ownedSkinChromas[0].split(',');
      const skinShardsAll = account.accCheckerData.skinShardsAll[0].split(',');
      // combine all owned items into one array
      let ownedCollection = [...ownedChamps, ...ownedSkins, ...ownedChromas, ...skinShardsAll];
      // process ownedCollection to remove empty strings and 'None' values + trim whitespace
      ownedCollection = ownedCollection
        .map((item) => item.trim())
        .filter((item) => item && item !== 'None');
      // only show accounts that have all selected items
      return selectedCollection.every((item) => ownedCollection.includes(item));
    });
    return filteredAccounts;
  };

  return (
    <div className="row right-action pb-0 flex-column-reverse flex-md-row">
      {/*<ListGridView />*/}
      <div className="col sort-select ms-auto mb-3 pb-0 mt-auto">
        <SearchForChampSkinChromaByInput
          accounts={accounts}
          selectedCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
        />
      </div>
      <div className="col-auto sort-select ms-auto mt-auto mb-3">
        <SortBySelection selectedSort={selectedSort} setSelectedSort={setSelectedSort} />
      </div>
    </div>
  );
};

export default TopBar;

const SearchForChampSkinChromaByInput = ({
  accounts,
  selectedCollection,
  setSelectedCollection,
}) => {
  const [allChamps, setAllChamps] = useState(new Set());
  const [allSkins, setAllSkins] = useState(new Set());
  const [allSkinShards, setAllSkinShards] = useState(new Set());
  const [allChromas, setAllChromas] = useState(new Set());
  // Process the set to remove empty strings and 'None' values + trim whitespace
  const processSet = (set) => {
    const processedSet = new Set();
    for (let item of set) {
      let processedItem = item.trim();
      if (processedItem && processedItem !== 'None') processedSet.add(processedItem);
    }
    return processedSet;
  };
  // Set all available champs, skins, chromas, and skin shards across all accounts
  useEffect(() => {
    setAllChamps(
      processSet(
        accounts.reduce((acc, curr) => {
          const champs = curr.accCheckerData.ownedChampions[0].split(',');
          return new Set([...acc, ...champs]);
        }, new Set()),
      ),
    );
    setAllSkins(
      processSet(
        accounts.reduce((acc, curr) => {
          const skins = curr.accCheckerData.ownedSkins[0].split(',');
          return new Set([...acc, ...skins]);
        }, new Set()),
      ),
    );
    setAllChromas(
      processSet(
        accounts.reduce((acc, curr) => {
          const chromas = curr.accCheckerData.ownedSkinChromas[0].split(',');
          return new Set([...acc, ...chromas]);
        }, new Set()),
      ),
    );
    setAllSkinShards(
      processSet(
        accounts.reduce((acc, curr) => {
          const skinShards = curr.accCheckerData.skinShardsAll[0].split(',');
          return new Set([...acc, ...skinShards]);
        }, new Set()),
      ),
    );
  }, [accounts]);

  // Used to render the dropdown items, grouped by category
  const renderMenu = (results, menuProps, state) => {
    let index = 0;
    const groups = [
      { title: 'Champion', data: allChamps },
      { title: 'Skin', data: allSkins },
      { title: 'Chroma', data: allChromas },
      { title: 'Skin Shard', data: allSkinShards },
    ];
    // Render each group separately
    const items = groups.map((group) => {
      const filteredData = [...group.data]
        // Don't show items that have already been selected
        .filter((item) => !selectedCollection.includes(item))
        // Filter items by the search text
        .filter((item) => item.toLowerCase().includes(state.text.toLowerCase()));
      // Don't render anything if there are no items to display
      if (filteredData.length === 0) return null;
      // If there is only one item, don't pluralize the title
      const title = filteredData.length > 1 ? `${group.title}s` : group.title;

      return (
        <Fragment key={group.title}>
          {index !== 0 && <Menu.Divider />}
          <Menu.Header>{title}</Menu.Header>
          {filteredData.map((item) => {
            const menuItem = (
              <MenuItem key={index} option={item} position={index}>
                <Highlighter search={state.text}>{item}</Highlighter>
              </MenuItem>
            );

            index += 1;
            return menuItem;
          })}
        </Fragment>
      );
    });

    const noItemsFound = items.every((item) => item === null);
    if (noItemsFound) {
      return (
        <Menu {...menuProps}>
          <MenuItem disabled>No items found.</MenuItem>
        </Menu>
      );
    }

    return <Menu {...menuProps}>{items}</Menu>;
  };

  const typeaheadRef = useRef();

  return (
    <div className="input-group">
      <MagnifyingGlassSVG typeaheadRef={typeaheadRef} />
      <Typeahead
        className="form-control"
        ref={typeaheadRef}
        id="champsSkins"
        labelKey="value"
        multiple
        placeholder="Search for a champion / skin / chroma"
        options={[...allChamps, ...allSkins, ...allChromas, ...allSkinShards]}
        onChange={setSelectedCollection}
        selected={selectedCollection}
        minLength={2}
        renderMenu={renderMenu}
      />
    </div>
  );
};

const MagnifyingGlassSVG = ({ typeaheadRef }) => {
  return (
    <span
      className="input-group-text"
      id="basic-addon1"
      onClick={() => typeaheadRef.current.focus()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="currentColor"
        className="bi bi-search"
        viewBox="0 0 16 16"
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
      </svg>
    </span>
  );
};

const SortBySelection = ({ selectedSort, setSelectedSort }) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSort(selectedValue);
  };

  return (
    <select
      className="form-select"
      onChange={handleSelectChange}
      value={selectedSort}
      style={{
        color: '#c1c1c1',
        border: '2px solid red',
        backgroundColor: '#171630',
      }}
    >
      <option value="Newest">Sort by: Newest</option>
      <option value="LowestPrice">Lowest Price</option>
      <option value="MostSkins">Most Skins</option>
      <option value="FewestSkins">Fewest Skins</option>
      <option value="MostChamps">Most Champs</option>
    </select>
  );
};

const ListGridView = () => {
  return (
    <div className="col-auto nav grid-view" id="pills-tab" role="tablist">
      <button
        type="button"
        className="btn btn-outline-light active"
        id="grid-tab"
        data-bs-toggle="pill"
        data-bs-target="#grid"
        role="tab"
        aria-controls="grid"
        aria-selected="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-grid my-auto"
          viewBox="0 0 16 16"
        >
          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
        </svg>
        Grid View
      </button>
      <button
        type="button"
        className="btn btn-outline-light"
        id="list-tab"
        data-bs-toggle="pill"
        data-bs-target="#list"
        role="tab"
        aria-controls="list"
        aria-selected="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-list-ul my-auto"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
          />
        </svg>
        List View
      </button>
    </div>
  );
};
