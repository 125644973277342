export const HeaderImg = () => {
  // used for staging / production
  const domain =
    process.env.REACT_APP_API_ENDPOINT === 'http://localhost:5000'
      ? 'http://localhost:3000'
      : process.env.REACT_APP_API_ENDPOINT;

  // use preloaded images
  const headerXsm = domain + '/header-xsm.webp';
  const headerMd = domain + '/header-md.webp';
  const headerLg = domain + '/header-lg.webp';

  return (
    <>
      <figure>
        <picture>
          <source media="(max-width: 480px)" srcSet={headerXsm} data-src={headerXsm} />
          <source media="(max-width: 1400px)" srcSet={headerMd} data-src={headerMd} />
          <source media="(min-width: 1401px)" srcSet={headerLg} data-src={headerLg} />
          <img
            className="homepage-header"
            src={headerLg}
            data-src-base="../../assets/images/"
            data-src={`{xs:${headerXsm},md:${headerMd},xl:${headerLg}}`}
            alt="Homepage header"
            draggable="false"
          />
        </picture>
      </figure>
    </>
  );
};
