import { lazy, Suspense } from 'react';
import HomeHeader from './components/HomeHeader';
import Services from './components/Services';
const OurSafety = lazy(() => import('./components/OurSafety'));
const SecurityPrivacy = lazy(() => import('./components/SecurityPrivacy'));
const FaqLandingPage = lazy(() =>
  import('../../shared/components/FaqLandingPage'),
);
const StopHesitating = lazy(() =>
  import('../../shared/components/StopHesitating'),
);
const DiscordMembers = lazy(() =>
  import('../../shared/components/discord-members'),
);

const Home = () => {
  return (
    <>
      <HomeHeader />
      <Services />
      <Suspense>
        <OurSafety />
        {/* <PurchaseFlow /> */}
        <SecurityPrivacy />
        <FaqLandingPage />
        {/* <Reviews /> // fix(main-website): #27 */}
        <StopHesitating />
        <DiscordMembers />
      </Suspense>
    </>
  );
};

export default Home;
