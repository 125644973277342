import { useState, useEffect, lazy, Suspense } from 'react';
import { Else, If, Then } from 'react-if';
import { useLocation } from 'react-router-dom';

import SvgDiscord from '../../shared/icons/SvgDiscord';
import useAuth from '../../hooks/useAuth';

const GoogleLoginPopup = lazy(() => import('./GoogleLoginPopup'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const LoginModal = lazy(() => import('./LoginModal'));
const RegisterModal = lazy(() => import('./RegisterModal'));

const storeOriginalUrl = () => {
  // Get the current page's URL
  const currentUrl = window.location.href;
  // Retrieve the existing data from local storage (if any)
  const storedData = JSON.parse(localStorage.getItem('originalUrls')) || {};

  // Store the current URL along with its origin (e.g., localhost or discord.com)
  if (currentUrl.startsWith(`${process.env.FRONT_END_URL}`)) {
    storedData['localhost'] = currentUrl;
  } else if (currentUrl.startsWith('https://discord.com')) {
    storedData['discord'] = currentUrl;
  }

  // Save the updated data back to local storage
  localStorage.setItem('originalUrls', JSON.stringify(currentUrl));
};

const getDiscordLoginUrl = () => {
  storeOriginalUrl();
  const p = new URLSearchParams();
  p.append('client_id', process.env.REACT_APP_DISCORD_CLIENT_ID);
  p.append('redirect_uri', `${process.env.REACT_APP_DISCORD_API_ENDPOINT}/auth/discord-login`);
  p.append('response_type', 'code');
  p.append('scope', 'identify email');
  return `https://discord.com/api/oauth2/authorize?${p.toString()}`;
};

const Auth = () => {
  const { user } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user) {
      document.getElementById('auth-close-btn').click();
    }
  }, [user]);
  const [forgotPassword, setForgotPassword] = useState(false);

  // Function to update the forgotPassword state
  const handleForgotPassword = (value) => {
    setForgotPassword(value);
  };

  // Reload the page after the modal is closed, only at accounts page due to error with double modal open (at buying without logged in)
  const location = useLocation();
  useEffect(() => {
    const modalElement = document.querySelector('.modal');
    const handleModalHidden = () => {
      if (location.pathname === '/accounts') window.location.reload();
    };
    modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [location]);

  return (
    <div
      className="modal login fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {forgotPassword ? (
        <Suspense>
          <ForgotPassword userType="customer" setForgotPassword={setForgotPassword} />
        </Suspense>
      ) : (
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body login">
              <button
                type="button"
                id="auth-close-btn"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <h2 className="mb-4">{isLogin ? 'Login' : 'Register'}</h2>

              <div className="d-flex flex-wrap gap-2 justify-content-center">
                <button
                  type="button"
                  className="social-login btn btn-primary discord-btn d-flex align-items-end justify-content-center gap-2"
                  style={{
                    height: '44px',
                    fontSize: '14px',
                    margin: '0px',
                    maxWidth: '204px',
                  }}
                  onClick={() => {
                    window.location.href = getDiscordLoginUrl();
                  }}
                >
                  <div style={{ marginBottom: '-2px' }}>
                    <SvgDiscord />
                  </div>
                  <span>
                    Continue with <strong>Discord</strong>
                  </span>
                </button>
                <Suspense>
                  <GoogleLoginPopup />
                </Suspense>
              </div>

              <div className="col-12 mt-3">
                <div className="or">
                  <span>or</span>
                </div>
                {errorMessage && (
                  <div style={{ color: 'red' }} className="or">
                    {errorMessage}
                  </div>
                )}
              </div>
              <Suspense>
                <If condition={isLogin}>
                  <Then>
                    <LoginModal
                      setErrorMessage={setErrorMessage}
                      setIsLogin={setIsLogin}
                      userType="customer"
                      onForgotPassword={handleForgotPassword}
                    />
                  </Then>
                  <Else>
                    <RegisterModal setErrorMessage={setErrorMessage} setIsLogin={setIsLogin} />
                  </Else>
                </If>
              </Suspense>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
