import pepeLoveEmoji from '../../assets/images/PepeLove.png';

// Payment icons import
import stripe from '../../assets/images/stripe.png';
import visa from '../../assets/images/visa.png';
import applepay from '../../assets/images/applepay.png';
import gpay from '../../assets/images/gpay.png';
import giro from '../../assets/images/giro.png';
import klarna from '../../assets/images/klarna.webp';
import paypal from '../../assets/images/paypal-sm.webp';
// End Payment icons import

// Social Media icons
import discordIcon from '../../assets/images/Discord-icon.svg';
import instagramIcon from '../../assets/images/instagram-icon.svg';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import tiktokIcon from '../../assets/images/tiktok-icon.svg';
import youtubeIcon from '../../assets/images/youtube-icon.svg';
// End Social Media icons

// Home-Header-Images
import headerXsm from '../../assets/images/header-xsm.webp';
import headerMd from '../../assets/images/header-md.webp';
import headerLg from '../../assets/images/header-lg.webp';
// END Home-Header-Images

// Home services
import jinx from '../../assets/images/jinx.webp';
import ryze from '../../assets/images/ryze.webp';
// END Home services

// home Our Safety
import appearOfflineIcon from '../../assets/images/appear-offline-icon.svg';
import vpnLocationIcon from '../../assets/images/vpn-location-icon.svg';
import fcWarrantyIcon from '../../assets/images/fc-warranty-icon.svg';
// End home Our Safety

// Reviews Stars
import starFill from '../../assets/images/star-fill.svg';
// End Reviews Stars

// Boosting Header
import divisionHeaderXsm from '../../assets/images/division-header-xsm.webp';
import divisionHeaderMd from '../../assets/images/division-header-md.webp';
import divisionHeaderLg from '../../assets/images/division-header-lg-300height.webp';

// Boosting premium-features
import appearOffIcon from '../../assets/images/appear-off-icon.svg';
import orderTrackIcon from '../../assets/images/order-track-icon.svg';
import vpnEncrIcon from '../../assets/images/vpn-encr-icon.svg';
import extraOptionIcon from '../../assets/images/extra-option.svg';
import liveSportIcon from '../../assets/images/live-sport-icon.svg';
import ewarrantyIconIcon from '../../assets/images/warranty-icon.svg';
// End Boosting premium-features

// Acount shop Header
import accountShHeaderXsm from '../../assets/images/account-sh-header-xsm.webp';
import accountShHeaderMd from '../../assets/images/account-sh-header-md.webp';
import accountShHeaderLg from '../../assets/images/account-sh-header-lg-300.webp';
// End Acount shop Header

// Acount shop quality
import ownerIcon from '../../assets/images/owner-icon.webp';
import qualityIcon from '../../assets/images/quality-icon.webp';
import warrantyIcon from '../../assets/images/Security-icon-3.webp';
import qmIcon from '../../assets/images/qm-img-2.webp';
// End Acount shop quality

// Acount shop Efficiency
import effkeyimg from '../../assets/images/eff-key-img-02.png';
// End Acount shop Efficiency

// Acount Card
import shopCardImg from '../../assets/images/master.webp';
import cardIcon1 from '../../assets/images/card-icon-001.png';
import cardIcon2 from '../../assets/images/card-icon-002.png';
import cardIcon3 from '../../assets/images/card-icon-003.png';
import cardIcon4 from '../../assets/images/card-icon-004.png';
// End Acount Card

// Acount List Card
import listCardIcon1 from '../../assets/images/shop-lis-icon-001.svg';
import listCardIcon2 from '../../assets/images/shop-lis-icon-002.svg';
// End Acount List Card

// contact header
import contactHeaderXsm from '../../assets/images/contact-header-xsm.webp';
import contactHeaderMd from '../../assets/images/contact-header-md.webp';
import contactHeaderLg from '../../assets/images/contact-header-lg.webp';
// End contact header

// contact real header
import realtimeImg from '../../assets/images/realtime-img.webp';
// End contact real header

// premium-smurfs tab

import euwSvg from '../../assets/images/euw.svg';
import euneSvg from '../../assets/images/eune.svg';
import naSvg from '../../assets/images/na-icon.svg';
// premium-smurfs tab

// premium-smurfs Price Card
import packIcon from '../../assets/images/pack-icon.png';
import prePackImg from '../../assets/images/pre-pack-img.png';
// premium-smurfs Price Card

// content legal info
import userPin from '../../assets/images/user-pin-icon.svg';
import clockIcon from '../../assets/images/clock-icon.svg';
import legalBgImg from '../../assets/images/legal-bg-img.webp';
// content legal info

// End Division Boost
import gold2 from '../../assets/images/gold.webp';
import arrRight from '../../assets/images/arr-right-2.svg';
// End Division Boost

// LOGO
import boostopiaLogo from '../../assets/images/boostopia-logo-3.svg';
import adminLogo from '../../assets/images/boostopia-logo-6.svg';

// Settings
import iconLogo from '../../assets/images/logo-yellow-black.svg';
import thumbPro from '../../assets/images/thumb-pro.jpg';
import flash from '../../assets/images/flash.webp';

// hesitating
import imgLeft from '../../assets/images/stop-h-img-l.webp';
import imgRight from '../../assets/images/stop-h-img-r.webp';

// order-empty
import orderEmpty from '../../assets/images/order-empty.png';

// accEmptyImg
import accEmptyImg from '../../assets/images/acc-empty-img.png';

// BOOSTING PAGE IMAGES
import map from '../../assets/images/map-icon.svg';
import flag from '../../assets/images/flag-icon.svg';

// Chat images
import chatImg from '../../assets/images/h-img-001.jpg';

// my
import questionPing from '../../assets/images/question_ping.webp';
import championCapsule from '../../assets/images/champion-capsule.webp';

// Rank Images

import divisionWhite from '../../assets/images/DivisionWhite.webp';
import unrankedWhite from '../../assets/images/unranked2.webp';
import unranked from '../../assets/images/unranked.webp';
import iron from '../../assets/images/iron.webp';
import bronze from '../../assets/images/bronze.webp';
import silver from '../../assets/images/silver.webp';
import gold from '../../assets/images/gold.webp';
import platinum from '../../assets/images/platinum.webp';
import emerald from '../../assets/images/emerald.webp';
import diamond from '../../assets/images/diamond.webp';
import master from '../../assets/images/master.webp';
import grandmaster from '../../assets/images/grandmaster.webp';
import challenger from '../../assets/images/challenger.webp';

import victory from '../../assets/images/victory.webp';

import fill from '../../assets/images/fill.webp';

const rankImages = [
  {
    title: 'challenger',
    img: challenger,
  },
  {
    title: 'grandmaster',
    img: grandmaster,
  },
  {
    title: 'master',
    img: master,
  },
  {
    title: 'diamond',
    img: diamond,
  },
  {
    title: 'emerald',
    img: emerald,
  },
  {
    title: 'platinum',
    img: platinum,
  },
  {
    title: 'gold',
    img: gold,
  },
  {
    title: 'silver',
    img: silver,
  },
  {
    title: 'bronze',
    img: bronze,
  },
  {
    title: 'iron',
    img: iron,
  },
  {
    title: 'unranked',
    img: unranked,
  },
];

const findRankImage = (title) => {
  const foundImg = rankImages.find((x) => x.title === title);
  return foundImg ? foundImg.img : null;
};

export {
  fill,
  pepeLoveEmoji,
  divisionWhite,

  // Rank Images
  findRankImage,
  // Payment icons
  stripe,
  applepay,
  gpay,
  giro,
  visa,
  klarna,
  paypal,
  // End Payment icons

  // Social Media icons
  discordIcon,
  instagramIcon,
  facebookIcon,
  tiktokIcon,
  youtubeIcon,
  // End Social Media icons

  // Home-Header-Images
  headerXsm,
  headerMd,
  headerLg,
  // END Home-Header-Images

  // Home services
  jinx,
  ryze,
  // END Home services

  // home Our Safety
  appearOfflineIcon,
  vpnLocationIcon,
  fcWarrantyIcon,
  // End home Our Safety

  // Reviews Stars
  starFill,
  // End Reviews Stars

  // Boosting Header
  divisionHeaderXsm,
  divisionHeaderMd,
  divisionHeaderLg,
  // End Boosting Header

  // Boosting premium-features
  appearOffIcon,
  orderTrackIcon,
  vpnEncrIcon,
  extraOptionIcon,
  liveSportIcon,
  ewarrantyIconIcon,
  // End Boosting premium-features

  // Acount shop Header
  accountShHeaderXsm,
  accountShHeaderMd,
  accountShHeaderLg,
  // End Acount shop Header

  //Acount shop quality
  ownerIcon,
  qualityIcon,
  warrantyIcon,
  qmIcon,
  // End Acount shop quality

  // Acount shop Efficiency
  effkeyimg,
  // End Acount shop Efficiency

  // Acount Card
  shopCardImg,
  cardIcon1,
  cardIcon2,
  cardIcon3,
  cardIcon4,
  // End Acount Card

  // Acount List Card
  listCardIcon1,
  listCardIcon2,
  // End Acount List Card

  // contact header
  contactHeaderXsm,
  contactHeaderMd,
  contactHeaderLg,
  // End contact header

  // contact real header
  realtimeImg,
  // End contact real header

  // premium-smurfs tab
  euwSvg,
  euneSvg,
  naSvg,
  // premium-smurfs tab

  // premium-smurfs Price Card
  packIcon,
  prePackImg,
  // premium-smurfs Price Card

  // content legal info
  userPin,
  clockIcon,
  legalBgImg,
  // content legal info

  // End Division Boost
  gold2,
  arrRight,
  // End Division Boost

  // LOGO
  boostopiaLogo,
  adminLogo,

  // Settings
  iconLogo,
  thumbPro,
  flash,

  // hesitating
  imgLeft,
  imgRight,

  // order-empty
  orderEmpty,

  // accEmptyImg
  accEmptyImg,

  // BOOSTING PAGE IMAGES
  map,
  flag,

  // Chat images
  chatImg,

  //ranks
  unrankedWhite,
  unranked,
  iron,
  bronze,
  silver,
  gold,
  platinum,
  diamond,
  master,
  grandmaster,
  challenger,
  questionPing,
  championCapsule,
  victory,
};
