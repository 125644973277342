import { useEffect, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';

import AccountShopHeader from './components/accountShopHeader';
import Listing from './components/listing';
import TrustpilotBar from '../../shared/components/TrustpilotBar';
const Quality = lazy(() => import('./components/quality'));
const FaqLandingPage = lazy(() => import('../../shared/components/FaqLandingPage'));
const DiscordMembers = lazy(() => import('../../shared/components/discord-members'));

const AccountShop = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.title = 'LoL Account Shop - Buy League of Legends Smurfs | Boostopia';
  }, []);
  return (
    <>
      <AccountShopHeader />
      <TrustpilotBar />
      {/* Add Handleveled accs later
      <Tab />
      <Isolator /> */}
      <Listing />
      <Suspense>
        <Quality />
        <FaqLandingPage />
        <hr />
        <div className="d-flex flex-column align-items-center justify-content-center m-5">
          <p className="mb-1">Interested in our Boosts instead?</p>
          <Link to="/boosting" type="button" className="btn btn-outline-light my-hover my-font">
            Go to Boosting
          </Link>
        </div>
        <DiscordMembers />
      </Suspense>
    </>
  );
};

export default AccountShop;
