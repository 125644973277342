import { useState, lazy, Suspense } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Loader } from '../../../shared/components/Loader';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const BoostingTabContent = lazy(() => import('./BoostingTabContent'));

// used for staging / production
const domain =
  process.env.REACT_APP_API_ENDPOINT === 'http://localhost:5000'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_API_ENDPOINT;

const BoostingTabs = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get('tab');

  const tooltip = (text) => (
    <Tooltip className="my-tooltip" id="tooltip">
      {text}
    </Tooltip>
  );

  const [tooltipIsActive, setTooltipIsActive] = useState([false, false, false]);

  return (
    // use preloaded background
    <div
      className="division-tabs"
      style={{
        backgroundImage: `url(${domain}/bg-image-tabs.webp)`,
      }}
    >
      <div className="container">
        <div className="row mt-3 mx-1">
          <div className="col-12">
            <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
              <Link
                to="/boosting/?tab=division"
                className="nav-item text-decoration-none"
                role="presentation"
                replace
              >
                <OverlayTrigger
                  show={tooltipIsActive[0]}
                  placement="top"
                  overlay={tooltip('We will get you to your desired rank')}
                >
                  <button
                    onClick={() => setTooltipIsActive([true, false, false])}
                    className={`nav-link ${tab === 'division' || tab === null ? 'active' : ''}`}
                    id="pills-Division-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Division"
                    type="button"
                    role="tab"
                    aria-controls="pills-Division"
                    aria-selected={tab === 'division'}
                  >
                    Division Boost
                  </button>
                </OverlayTrigger>
              </Link>
              <Link
                to="/boosting/?tab=net-wins"
                className="nav-item text-decoration-none"
                role="presentation"
                replace
              >
                <OverlayTrigger
                  show={tooltipIsActive[1]}
                  placement="top"
                  overlay={tooltip('Get Wins! For each lose you will get an extra win')}
                >
                  <button
                    onClick={() => {
                      setTooltipIsActive([false, true, false]);
                    }}
                    className={`nav-link ${tab === 'net-wins' ? 'active' : ''}`}
                    id="pills-Netwins-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Netwins"
                    type="button"
                    role="tab"
                    aria-controls="pills-Netwins"
                    aria-selected={tab === 'net-wins'}
                  >
                    Net Wins
                  </button>
                </OverlayTrigger>
              </Link>
              <Link
                to="/boosting/?tab=placement"
                className="nav-item text-decoration-none"
                role="presentation"
                replace
              >
                <OverlayTrigger
                  show={tooltipIsActive[2]}
                  placement="top"
                  overlay={tooltip('Still unranked? We will place you on the ladder')}
                >
                  <button
                    onClick={() => {
                      setTooltipIsActive([false, false, true]);
                    }}
                    className={`nav-link ${tab === 'placement' ? 'active' : ''}`}
                    id="pills-Placements-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Placements"
                    type="button"
                    role="tab"
                    aria-controls="pills-Placements"
                    aria-selected={tab === 'placement'}
                  >
                    Placements
                  </button>
                </OverlayTrigger>
              </Link>
            </ul>

            <Suspense
              fallback={
                <div style={{ height: '27rem' }}>
                  <Loader />
                </div>
              }
            >
              {/* Preload image */}
              {(() => {
                const link = document.createElement('link');
                link.rel = 'preload';
                link.as = 'image';
                link.href = `${domain}/q-start-bg.webp`;
                document.head.appendChild(link);
              })()}
              <BoostingTabContent tab={tab} setTooltipIsActive={setTooltipIsActive} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostingTabs;
