import { useEffect, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';

import BoostingHeader from './components/boosting.header';
import TrustpilotBar from '../../shared/components/TrustpilotBar';
import BoostingTabs from './components/boosting.tabs';
const PremiumFeature = lazy(() => import('./components/premium.features'));
const FaqBoosting = lazy(() => import('../../shared/components/FaqBoosting'));
const DiscordMembers = lazy(() => import('../../shared/components/discord-members'));

const Boosting = () => {
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    document.title = 'LoL ELO Boosting - Buy League of Legends Boosts | Boostopia';
  }, []);

  return (
    <>
      <BoostingHeader />
      <TrustpilotBar />

      <BoostingTabs />

      <div className="premium-features">
        <Suspense fallback={<div style={{ height: '37rem' }}></div>}>
          <PremiumFeature />
        </Suspense>
      </div>

      <Suspense>
        {/* <PurchaseFlow /> */}
        <FaqBoosting />
        {/* <StopHesitating executeScroll={executeScroll} /> */}
        <hr />
        <div className="d-flex flex-column align-items-center justify-content-center m-5">
          <p className="mb-1">Want to buy an Account instead?</p>
          <Link to="/accounts" type="button" className="btn btn-outline-light my-hover my-font">
            Visit Account Shop
          </Link>
        </div>
        <DiscordMembers />
      </Suspense>
    </>
  );
};

export default Boosting;
