import { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Bar = ({ requestFilter, setRequestFilter, makeRequest, getAllAccounts }) => {
  const [selectedServer, setSelectedServer] = useState([]);
  const [selectedRank, setSelectedRank] = useState([]);
  const [selectedChampions, setSelectedChampions] = useState([]);
  const [selectedSkins, setSelectedSkins] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedSort, setSelectedSort] = useState([]);

  // dont make a request on the first render
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    makeRequest(getAllAccounts(requestFilter));
  }, [requestFilter]);

  // Function to update the parent state
  const updateRequestFilter = () => {
    setRequestFilter({
      server: selectedServer,
      rank: selectedRank,
      champions: selectedChampions,
      skins: selectedSkins,
      price: selectedPrice,
      sort: selectedSort,
    });
  };

  // Event handler for checkbox changes
  const handleCheckboxChange = (category, value) => {
    return new Promise((resolve) => {
      switch (category) {
        case 'server':
          setSelectedServer((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
          );
          break;
        case 'rank':
          setSelectedRank((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
          );
          break;
        case 'champions':
          setSelectedChampions((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
          );
          break;
        case 'skins':
          setSelectedSkins((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
          );
          break;
        case 'price':
          setSelectedPrice((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value],
          );
          break;
        default:
          break;
      }
      resolve();
    });
  };

  const firstUpdateTwo = useRef(true);
  useEffect(() => {
    if (firstUpdateTwo.current) {
      firstUpdateTwo.current = false;
      return;
    }
    updateRequestFilter();
  }, [selectedServer, selectedRank, selectedChampions, selectedSkins, selectedPrice]);

  return (
    <div className="mt-3 col-lg-3 col-md-12 col-sm-12 left-filters">
      <FilterAccordion handleCheckboxChange={handleCheckboxChange} />
    </div>
  );
};

export default Bar;

const FilterAccordion = ({ handleCheckboxChange }) => {
  const [openItems, setOpenItems] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const toggleItem = (key) => {
    setOpenItems({
      ...openItems,
      [key]: !openItems[key],
    });
  };

  const [isCheckedEUW, setIsCheckedEUW] = useState(false);
  const [isCheckedEUNE, setIsCheckedEUNE] = useState(false);
  const [isCheckedNA, setIsCheckedNA] = useState(false);
  const [isCheckedOCE, setIsCheckedOCE] = useState(false);

  // if localstorage is empty, then wait 250ms for the ipapi.co api to return the country
  useEffect(() => {
    let timer;
    const handleUserCountry = async (userCountry) => {
      switch (userCountry) {
        case 'United States':
        case 'Canada':
        case 'Mexico':
        case 'Brazil':
        case 'Argentina':
        case 'Chile':
        case 'Colombia':
          setIsCheckedNA(true);
          await handleCheckboxChange('server', 'NA');
          break;
        case 'Australia':
        case 'New Zealand':
        case 'Fiji':
        case 'Papua New Guinea':
          setIsCheckedOCE(true);
          await handleCheckboxChange('server', 'OCE');
          break;
        case 'Germany':
        case 'France':
        case 'United Kingdom':
        case 'Italy':
        case 'Spain':
        case 'Netherlands':
        case 'Belgium':
        case 'Ireland':
        case 'Portugal':
        case 'Austria':
        case 'Switzerland':
          setIsCheckedEUW(true);
          await handleCheckboxChange('server', 'EUW');
          break;
        default:
          setIsCheckedEUW(true);
          setIsCheckedEUNE(true);
          await handleCheckboxChange('server', 'EUW');
          await handleCheckboxChange('server', 'EUNE');
          break;
      }
    };
    let userCountry = localStorage.getItem('userCountry');
    if (!userCountry) {
      timer = setTimeout(async () => {
        const userCountry = localStorage.getItem('userCountry');
        await handleUserCountry(userCountry);
      }, 350); // 350ms delay
    } else {
      timer = setTimeout(async () => {
        await handleUserCountry(userCountry);
      }, 0);
    }

    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => toggleItem('0')}>
          <FilterSVG />
          Server
        </Accordion.Header>
        <Accordion.Collapse in={openItems['0']}>
          <>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="filter-001"
                value="option1"
                checked={isCheckedEUW}
                onChange={() => {
                  setIsCheckedEUW(!isCheckedEUW);
                  handleCheckboxChange('server', 'EUW');
                }}
              />
              <span className="form-check-label" htmlFor="filter-001">
                Europe West
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="filter-002"
                value="option2"
                checked={isCheckedEUNE}
                onChange={() => {
                  setIsCheckedEUNE(!isCheckedEUNE);
                  handleCheckboxChange('server', 'EUNE');
                }}
              />
              <span className="form-check-label" htmlFor="filter-002">
                Europe Nordic & East
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="filter-003"
                value="option3"
                checked={isCheckedNA}
                onChange={() => {
                  setIsCheckedNA(!isCheckedNA);
                  handleCheckboxChange('server', 'NA');
                }}
              />
              <span className="form-check-label" htmlFor="filter-003">
                North America
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="filter-004"
                value="option4"
                checked={isCheckedOCE}
                onChange={() => {
                  setIsCheckedOCE(!isCheckedOCE);
                  handleCheckboxChange('server', 'OCE');
                }}
              />
              <span className="form-check-label" htmlFor="filter-004">
                Oceania
              </span>
            </label>
          </>
        </Accordion.Collapse>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={() => toggleItem('1')}>
          <FilterSVG />
          SoloQ Rank
        </Accordion.Header>
        <Accordion.Collapse in={openItems['1']}>
          <>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Rank-001"
                value="option4"
                onChange={() => handleCheckboxChange('rank', 'Unranked')}
              />
              <span className="form-check-label" htmlFor="Rank-001">
                Unranked
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                disabled
                className="form-check-input"
                type="checkbox"
                id="Rank-002"
                value="option5"
                onChange={() => {
                  handleCheckboxChange('rank', 'Iron');
                  handleCheckboxChange('rank', 'Bronze');
                  handleCheckboxChange('rank', 'Silver');
                }}
              />
              <span className="form-check-label" htmlFor="Rank-002">
                {/*Iron / Bronze / Silver*/} More available soon
              </span>
            </label>
            {/*
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Rank-003"
                value="option6"
                onChange={() => {
                  handleCheckboxChange('rank', 'Gold');
                  handleCheckboxChange('rank', 'Platinum');
                }}
              />
              <span className="form-check-label" htmlFor="Rank-003">
                Gold / Platinum
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Rank-009"
                value="option9"
                onChange={() => {
                  handleCheckboxChange('rank', 'Emerald');
                  handleCheckboxChange('rank', 'Diamond');
                }}
              />
              <span className="form-check-label" htmlFor="Rank-009">
                Emerald / Diamond
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Rank-007"
                value="option11"
                onChange={() => {
                  handleCheckboxChange('rank', 'Master');
                  handleCheckboxChange('rank', 'Grandmaster');
                  handleCheckboxChange('rank', 'Challenger');
                }}
              />
              <span className="form-check-label" htmlFor="Rank-007">
                Master+
              </span>
              </label>*/}
          </>
        </Accordion.Collapse>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header onClick={() => toggleItem('2')}>
          <FilterSVG />
          Champions
        </Accordion.Header>
        <Accordion.Collapse in={openItems['2']}>
          <>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Champions-001"
                value="option12"
                onChange={() => handleCheckboxChange('champions', '0-60')}
              />
              <span className="form-check-label" htmlFor="Champions-001">
                0 - 60
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Champions-002"
                value="option13"
                onChange={() => handleCheckboxChange('champions', '60-120')}
              />
              <span className="form-check-label" htmlFor="Champions-002">
                60 - 120
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Champions-003"
                value="option14"
                onChange={() => handleCheckboxChange('champions', '120')}
              />
              <span className="form-check-label" htmlFor="Champions-003">
                120+
              </span>
            </label>
          </>
        </Accordion.Collapse>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header onClick={() => toggleItem('3')}>
          <FilterSVG />
          Skins
        </Accordion.Header>
        <Accordion.Collapse in={openItems['3']}>
          <>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Skins-001"
                value="option16"
                onChange={() => handleCheckboxChange('skins', '0-50')}
              />
              <span className="form-check-label" htmlFor="Skins-001">
                0 - 50
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Skins-002"
                value="option17"
                onChange={() => handleCheckboxChange('skins', '50-250')}
              />
              <span className="form-check-label" htmlFor="Skins-002">
                50 - 250
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Skins-003"
                value="option18"
                onChange={() => handleCheckboxChange('skins', '250')}
              />
              <span className="form-check-label" htmlFor="Skins-003">
                250+
              </span>
            </label>
          </>
        </Accordion.Collapse>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header onClick={() => toggleItem('4')}>
          <FilterSVG />
          Price
        </Accordion.Header>
        <Accordion.Collapse in={openItems['4']}>
          <>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Price-001"
                value="option20"
                onChange={() => handleCheckboxChange('price', '0-50')}
              />
              <span className="form-check-label" htmlFor="Price-001">
                0 - 50 €
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Price-002"
                value="option21"
                onChange={() => handleCheckboxChange('price', '50-150')}
              />
              <span className="form-check-label" htmlFor="Price-002">
                50 - 150 €
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Price-003"
                value="option22"
                onChange={() => handleCheckboxChange('price', '150-400')}
              />
              <span className="form-check-label" htmlFor="Price-003">
                150 - 400 €
              </span>
            </label>
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="Price-004"
                value="option23"
                onChange={() => handleCheckboxChange('price', '400')}
              />
              <span className="form-check-label" htmlFor="Price-004">
                400+ €
              </span>
            </label>
          </>
        </Accordion.Collapse>
        <hr className="mt-0" />
      </Accordion.Item>
    </Accordion>
  );
};

const FilterSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="#c1c1c1"
      className="bi bi-filter me-1"
      viewBox="0 0 16 16"
    >
      <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
    </svg>
  );
};
