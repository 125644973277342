import { lazy, Suspense } from 'react';
import Navigation from './navigation';
const Footer = lazy(() => import('./footer'));

const Layout = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        {/* <HelmetProvider>
          <Helmet>
            <script
              src="//code.tidio.co/oreswvr8mxa8nhrzqr8shriuq8v0ncyg.js"
              async
            ></script>
          </Helmet>
        </HelmetProvider> */}

        <Navigation />
        <main>{children}</main>
        <Suspense>
          <Footer />
        </Suspense>
      </div>
    </>
  );
};
export default Layout;
