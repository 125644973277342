import trustpilotStars from '../../assets/icons/trustpilot-stars.svg';
import trustpilotLogo from '../../assets/icons/tp-logo-white.svg';

const ShortTpBadge = ({ styleObj }) => {
  return (
    <a
      className="py-2 px-2 d-flex align-items-center gap-2 text-white text-decoration-none"
      style={styleObj}
      target="_blank"
      href="https://www.trustpilot.com/review/boostopia.gg"
      rel="noreferrer"
    >
      <img
        draggable="false"
        src={trustpilotStars}
        alt="5 Trustpilot stars"
        style={{
          position: 'relative',
          height: '22px',
          width: '110px',
          left: '0',
          top: '0',
        }}
      />
      <span style={{ fontSize: '0.8rem' }}>on</span>
      <img
        draggable="false"
        src={trustpilotLogo}
        alt="Trustpilot"
        style={{
          position: 'relative',
          height: '22px',
          width: '90px',
          left: '0',
          top: '-3px',
        }}
      />
    </a>
  );
};

export default ShortTpBadge;
