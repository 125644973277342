import useToasts from '../../hooks/useToasts';

export default function Toasts() {
  const { toasts, removeToast } = useToasts();

  return (
    <div id="toasts-container" className="position-fixed top-0 end-0 p-3" style={{ zIndex: 300 }}>
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`toast show align-items-center text-white bg-${toast.variant} border-0`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{toast.message}</div>
            <button
              type="button"
              className="btn-close btn-close-white ms-auto me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => removeToast(toast.id)}
            ></button>
          </div>
        </div>
      ))}
    </div>
  );
}
